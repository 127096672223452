<template>
  <span v-away="close" class="relative inline-flex shadow-sm rounded">
    <button
      type="button"
      class="relative inline-flex items-center px-4 py-1 rounded-l border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
      @click.prevent="$emit('input', !value)"
    >
      {{ actions.label }}
    </button>
    <span class="-ml-px relative block">
      <button
        type="button"
        class="relative inline-flex items-center px-2 py-1 rounded-r border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none"
        id="option-menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        @click.prevent="$emit('input', !value)"
      >
        <span class="sr-only">Open options</span>
        <!-- Heroicon name: solid/chevron-down -->
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <div
        v-show="value && filteredOptions"
        class="origin-top-right z-50 absolute right-0 w-56 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="option-menu-button"
        tabindex="-1"
      >
        <div class="py-1" role="none">
          <a
            href="#"
            v-for="(option, index) in filteredOptions"
            :key="`action-${index}`"
            class="text-gray-700 block px-6 py-2 text-sm text-left hover:bg-gray-100"
            role="menuitem"
            tabindex="-1"
            @click.prevent="() => emit(option.component)"
          >
            {{ option.label }}</a
          >
        </div>
      </div>
    </span>
  </span>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DataTableRowDropdown',
  props: ['value', 'item', 'actions'],
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    emit(component) {
      this.$emit('event', { component, item: this.item })
      this.close()
    },
  },
  computed: {
    filteredOptions() {
      return _.filter(this.actions.options, option => this.can(option.permission))
    },
  },
}
</script>

<style></style>
